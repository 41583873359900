
import {
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonFab,
  IonFabButton,
  IonIcon
} from '@ionic/vue'
import { volumeMediumOutline } from 'ionicons/icons'
import { defineComponent } from 'vue'
import { getRightToLeftLanguages } from '../db'

export default defineComponent({
  props: ['languageName', 'languageId', 'vocab', 'vocabId'],
  components: {
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonFab,
    IonFabButton,
    IonIcon
  },
  data() {
    return {
      audioLoaded: false,
      rightToLeftLanguages: getRightToLeftLanguages()
    }
  },
  computed: {
    audioUrl() {
      return `/assets/sounds/${this.vocabId
        .toString()
        .padStart(3, '0')}_${this.languageId.toUpperCase()}.mp3`
    }
  },
  setup() {
    return {
      volumeMediumOutline
    }
  },
  methods: {
    playAudio() {
      if (!this.audioLoaded) {
        this.audioLoaded = true
      }

      setTimeout(() => {
        const sounds = document.getElementsByTagName('audio')
        for (let i = 0; i < sounds.length; i++) {
          sounds[i].pause()
        }

        const audio = this.$refs.audio as HTMLAudioElement

        audio.currentTime = 0
        audio.play()
      }, 0)
    }
  }
})

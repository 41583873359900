
import {
  IonPage,
  IonButtons,
  IonHeader,
  IonContent,
  IonToolbar,
  IonIcon,
  IonFabButton
} from '@ionic/vue'
import VocabDetailTile from '../components/VocabDetailTile.vue'
import FavButton from '../components/FavButton.vue'
import { arrowBackOutline } from 'ionicons/icons'
import { computed, defineComponent, ref, watch } from 'vue'
import { getVocab } from '../db'
import { useRoute } from 'vue-router'
import { settings } from '../settings'
import { getLanguages } from '@/db'

export default defineComponent({
  name: 'VocabDetail',
  components: {
    IonPage,
    IonButtons,
    IonHeader,
    IonToolbar,
    IonContent,
    IonIcon,
    VocabDetailTile,
    FavButton,
    IonFabButton
  },
  setup() {
    const route = useRoute()
    const vocabId = ref(parseInt(route.params.id as string))
    const languages = getLanguages()

    watch(
      () => route.params.id,
      (newId) => {
        const id = parseInt(newId as string)

        if (route.name === 'VocabDetail' && id) {
          vocabId.value = id
        }
      }
    )

    const vocab = computed(() => {
      const vocab = getVocab(vocabId.value)

      if (!vocab) {
        return null
      }

      return vocab
    })

    const mainTranslation = computed(() =>
      !vocab.value
        ? ''
        : vocab.value.translations[settings.value.primaryLanguage]
    )

    const secondaryTranslations = computed(() =>
      !vocab.value
        ? []
        : Object.keys(vocab.value.translations)
            .filter((l: string) =>
              settings.value.secondaryLanguages.includes(l)
            )
            .map((l) => ({
              languageId: l,
              languageName:
                languages.find((lang) => lang.id === l)?.label || 'Sprache',
              translation: !vocab.value ? 'Wort' : vocab.value.translations[l]
            }))
    )

    return {
      vocab,
      vocabId,
      arrowBackOutline,
      mainTranslation,
      secondaryTranslations
    }
  }
})

import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d3937ffc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "grow w-full min-w-0 p-2" }
const _hoisted_4 = { class: "flex-none flex w-12 items-center m-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, { class: "m-1 mb-2" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.audioLoaded)
          ? (_openBlock(), _createElementBlock("audio", {
              key: 0,
              ref: "audio",
              src: _ctx.audioUrl,
              class: "hidden"
            }, null, 8, _hoisted_2))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_ion_card_header, { class: "p-2" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_card_subtitle, { class: "text-xs" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.languageName), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_card_title, { class: "text-base font-medium" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.vocab), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_ion_fab, {
            vertical: "center",
            horizontal: "end",
            slot: "fixed"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_fab_button, {
                onClick: _ctx.playAudio,
                class: "p-0 fav-button",
                color: "secondary"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    icon: _ctx.volumeMediumOutline,
                    class: "w-5 h-5"
                  }, null, 8, ["icon"])
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }))
}
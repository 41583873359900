import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2ad9dc73"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex items-center justify-center" }
const _hoisted_2 = { class: "h-32 w-32 mt-4 flex justify-center items-center" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "flex justify-center pl-5 pr-5" }
const _hoisted_5 = { class: "flex-row space-y-2 my-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_FavButton = _resolveComponent("FavButton")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_VocabDetailTile = _resolveComponent("VocabDetailTile")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_fab_button, {
                    "router-link": `/kapitel/${_ctx.vocab?.chapter || 1}`,
                    "router-direction": "back",
                    class: "p-0 fab-button",
                    color: "--ion-color-white"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        icon: _ctx.arrowBackOutline,
                        class: "w-5 h-5"
                      }, null, 8, ["icon"])
                    ]),
                    _: 1
                  }, 8, ["router-link"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_buttons, { slot: "end" }, {
                default: _withCtx(() => [
                  _createVNode(_component_FavButton, {
                    vocabId: _ctx.vocab?.id || 1
                  }, null, 8, ["vocabId"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("img", {
                  src: _ctx.vocab?.icon || `/assets/icon/vocab/${_ctx.vocab?.id}.svg`,
                  width: "100"
                }, null, 8, _hoisted_3)
              ])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("h3", null, _toDisplayString(_ctx.mainTranslation), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.secondaryTranslations, (translation) => {
              return (_openBlock(), _createBlock(_component_VocabDetailTile, {
                key: translation.languageId,
                languageId: translation.languageId,
                languageName: translation.languageName,
                vocab: translation.translation,
                vocabId: _ctx.vocabId
              }, null, 8, ["languageId", "languageName", "vocab", "vocabId"]))
            }), 128))
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}